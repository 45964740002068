import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { Instgram, Facebook, ExternalLink } from "../assets/icons";
function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const openNav = () => {
    toggleExpansion(true);
  };
  const closenav = () => {
    console.log("working");
    toggleExpansion(false);
  };
  return (
    <header className=" absolute t-0 right-0 left-0 bg-transparent z-10">
      <div className="flex items-center justify-between max-w-5xl mx-auto px-8 py-8 lg:py-8">
        <Link
          className="flex items-center no-underline text-white cursor-pointer"
          to="/"
        >
          <span className="font-bold text-3xl md:text-4xl robotoFont">
            {site.siteMetadata.title}
          </span>
        </Link>

        <span
          className="text-3xl cursor:pointer font-bold text-3xl md:text-4xl robotoFont text-white block md:hidden"
          onClick={() => {
            openNav();
          }}
        >
          &#9776;
        </span>

        <nav
          className={`hidden md:block md:flex md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `about`,
              title: `About`,
            },
            {
              route: `packages`,
              title: `Packages`,
            },
            {
              route: `contact`,
              title: `Contact`,
            },
          ].map((link) => (
            <Link
              className="block md:inline-block mt-4 md:mt-0 md:ml-10 lg:mt-0 lg:ml-20 no-underline text-white text-md cursor-pointer nav-item"
              key={link.title}
              to={link.route}
              spy={true}
              smooth={true}
              offset={-120}
              duration={900}
              hashSpy={true}
            >
              {link.title}
            </Link>
          ))}
          <a
            className=" mt-4 md:mt-0 md:ml-10 lg:mt-0 lg:ml-20 no-underline text-white text-md cursor-pointer flex nav-item"
            href="https://soundcloud.com/user-69091307"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Sound Cloud"
          >
            Sound Cloud
            <ExternalLink />
          </a>
        </nav>
        {/* mobile Nav */}

        <div
          id="mySidenav"
          className={`${
            isExpanded ? " w-full px-8" : "w-0"
          } sidenav text-white py-8 `}
        >
          <div className=" flex justify-between">
            <Link
              className="flex items-center no-underline text-white cursor-pointer"
              to="/"
            >
              <span className="font-bold text-3xl md:text-4xl robotoFont  w-40 overflow-hidden">
                {site.siteMetadata.title}
              </span>
            </Link>

            <span
              className="text-3xl cursor:pointer"
              onClick={() => {
                closenav();
              }}
            >
              &#9776;
            </span>
          </div>
          <div className="navigations py-5">
            {[
              {
                route: `about`,
                title: `About`,
              },
              {
                route: `packages`,
                title: `Packages`,
              },

              {
                route: `contact`,
                title: `Contact`,
              },
            ].map((link) => (
              <Link
                className="no-underline text-md cursor-pointer text-2xl  py-6 block"
                key={link.title}
                to={link.route}
                spy={true}
                smooth={true}
                offset={-120}
                duration={900}
                hashSpy={true}
                onClick={() => {
                  closenav();
                }}
              >
                {link.title}
              </Link>
            ))}
            <a
              className=" no-underline text-md cursor-pointer text-2xl  py-6 flex"
              href="https://soundcloud.com/user-69091307"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Sound Cloud"
            >
              Sound Cloud
              <ExternalLink />
            </a>
          </div>
          <div className="w-full flex justify-end">
            <img
              className=" overflow:hidden absolute mobile-banner"
              alt="Dark Mobile background with DJ Deck"
              src={require("../merlin/landingBanner.png")}
            ></img>
          </div>
          <div className="w-full absolute bottom-0 flex justify-center py-4 md:py-0">
            <a
              href="https://www.instagram.com/skeventsdj/"
              target="_blank"
              className="pr-10"
              rel="noreferrer noopener"
              aria-label="Instagram"
            >
              <Instgram />
            </a>
            <a
              href="https://facebook.com/SKEventsDJ/"
              target="_blank"
              className="pr-10"
              rel="noreferrer noopener"
              aria-label="Facebook"
            >
              <Facebook />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
