import React from "react";
import "../../css/style.scss";
import { useStaticQuery } from "gatsby";
import Logo_Colored from "../../assets/logos/logo-coloured";
import { Link, animateScroll as scroll } from "react-scroll";
const TopSection = () => {
  const data = useStaticQuery(graphql`
    query TopSection {
      allMarkdownRemark(filter: { frontmatter: { path: { eq: "/" } } }) {
        edges {
          node {
            frontmatter {
              description
              contact_now

              main_image {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const {
    description,
    contact_now,

    main_image,
  } = data.allMarkdownRemark.edges[0].node.frontmatter;
  console.log("data", data);
  const main = main_image.childImageSharp.fluid.src;
  return (
    <div
      className="w-full "
      style={{
        background:
          "radial-gradient(closest-side at 28% 400%, rgb(16, 16, 20) 20%, rgb(7, 58, 73) 400%) 0% 0% no-repeat padding-box padding-box transparent",
      }}
    >
      <div className="max-w-5xl flex flex-row flex-wrap relative items-center mx-auto py-24 px-8  topSection">
        <div className="flex flex-col  md:items-start lg:items-center lg:block w-full md:w-1/3">
          <div className="w-1/2 md:w-2/3">
            <Logo_Colored />
          </div>
          <div className="text-white pt-8 md:pt-6 text-md md:pr-5 lg:pr-20 lg:pl-0 robotoFont">
            {description}
          </div>
        </div>
        <div className="w-full md:w-2/3 relative pb-8 md:pb-0 main-banner">
          <img src={main} alt="SK Events DK deck illustration"></img>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-120}
            duration={900}
            hashSpy={true}
            className="absolute right-0 text-white py-4 md:py-5 md:px-10 rounded-md robotoFont contact-now cursor-pointer text-center"
            style={{
              background:
                "radial-gradient(closest-side at 28% 402%, rgb(226, 20, 143) 0%, rgb(183, 64, 170) 100%, rgb(64, 184, 244) 350%) 0% 0% no-repeat padding-box padding-box transparent",
            }}
          >
            {contact_now}
          </Link>
        </div>
        <div className="absolute w-auto audio-track">
          <img
            alt="Music sound waves"
            src={require("../../merlin/Rectangle-136.png")}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
