import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import TopSection from "../Sections/HomePage/topSection";
import About from "../Sections/HomePage/about";
import Packages from "../pages/Packages/index";
import Contact from "../Sections/HomePage/contact";

function IndexPage(props) {
  console.log(props);
  const PackagesData = props.data.Packages.edges;
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <section className="w-full">
        <TopSection />
        <About />
        <Packages data={PackagesData} />
        <Contact />
      </section>
    </Layout>
  );
}
export const query = graphql`
  query {
    Packages: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/Packages/" } }
    ) {
      edges {
        node {
          frontmatter {
            alt
            title
            package_img {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            features
          }
        }
      }
    }
  }
`;
export default IndexPage;
