import React from "react";

const FormInput = (props) => {
  const { data } = props;
  return (
    <input
      className="w-full focus:outline-none focus:shadow-outline border border-gray-700 rounded-md py-3 px-4 block appearance-none leading-normal  placeholder-gray-700 font-medium mb-8 "
      type={data.type}
      placeholder={`${data.required ? "* " : ""}${data.placeHolder}`}
      required={data.required}
      name={data.placeHolder}
      onChange={(e) => {
        props.handleChange(e);
      }}
    />
  );
};

export default FormInput;
