import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
function SEO({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={require("../assets/favicon/apple-touch-icon.png")}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={require("../assets/favicon/favicon-32x32.png")}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={require("../assets/favicon/favicon-16x16.png")}
      />

      <link
        rel="mask-icon"
        href={require("../assets/favicon/safari-pinned-tab.svg")}
        color="#101014"
      />
      <link
        rel="shortcut icon"
        href={require("../assets/favicon/favicon.ico")}
      />
      <meta name="msapplication-TileColor" content="#101014" />

      <meta name="theme-color" content="#101014" />
      {/* Primary Meta Tags */}
      <title>Newcastle-based events DJ for hire</title>
      <meta name="title" content="Newcastle-based events DJ for hire" />
      <meta
        name="description"
        content="Newcastle based, but getting people moving nationwide! SK Events is the perfect choice for your event, big or small. 
With years of experience behind the decks performing at weddings, clubs, birthday parties and everything in-between, we know how to wow your audience and create memories that last a lifetime."
      />
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://skeventsdj.co.uk/" />
      <meta property="og:title" content="Newcastle-based events DJ for hire" />
      <meta
        property="og:description"
        content="Newcastle based, but getting people moving nationwide! SK Events is the perfect choice for your event, big or small. 
With years of experience behind the decks performing at weddings, clubs, birthday parties and everything in-between, we know how to wow your audience and create memories that last a lifetime."
      />
      <meta
        property="og:image"
        content="https://skeventsdj.co.uk/preview.png"
      />
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://skeventsdj.co.uk/" />
      <meta
        property="twitter:title"
        content="Newcastle-based events DJ for hire"
      />
      <meta
        property="twitter:description"
        content="Newcastle based, but getting people moving nationwide! SK Events is the perfect choice for your event, big or small. 
With years of experience behind the decks performing at weddings, clubs, birthday parties and everything in-between, we know how to wow your audience and create memories that last a lifetime."
      />
      <meta
        property="twitter:image"
        content="https://skeventsdj.co.uk/preview.png"
      />
      <html lang="en" />
    </Helmet>
  );
}
{
}

SEO.defaultProps = {
  lang: `en`,
  keywords: [],
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default SEO;
