import React from "react";

function Logo_Colored() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1637.04 733.53"
    >
      <defs>
        <linearGradient
          id="a"
          x1="2530.2"
          x2="167.43"
          y1="750.83"
          y2="-1037.47"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#e2148f"></stop>
          <stop offset="1" stopColor="#40b8f4"></stop>
        </linearGradient>
        <linearGradient
          id="b"
          x1="2429.03"
          x2="66.26"
          y1="884.5"
          y2="-903.79"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="c"
          x1="2310.01"
          x2="-52.76"
          y1="1041.75"
          y2="-746.54"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="d"
          x1="2285.65"
          x2="-77.12"
          y1="1073.94"
          y2="-714.35"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="e"
          x1="2107.4"
          x2="-255.37"
          y1="1309.45"
          y2="-478.84"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="f"
          x1="1958.13"
          x2="-404.64"
          y1="1506.68"
          y2="-281.62"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="g"
          x1="1993.67"
          x2="-369.1"
          y1="1459.71"
          y2="-328.58"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="h"
          x1="2019.72"
          x2="-343.05"
          y1="1425.3"
          y2="-362.99"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="i"
          x1="1879.94"
          x2="-482.83"
          y1="1609.98"
          y2="-178.31"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="j"
          x1="2328.93"
          x2="-33.84"
          y1="1016.76"
          y2="-771.54"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="k"
          x1="2272.53"
          x2="-90.24"
          y1="1091.27"
          y2="-697.02"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="l"
          x1="2245.09"
          x2="-117.68"
          y1="1127.53"
          y2="-660.76"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="m"
          x1="2160.21"
          x2="-202.56"
          y1="1239.67"
          y2="-548.62"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="n"
          x1="2103.34"
          x2="-259.42"
          y1="1314.8"
          y2="-473.49"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="o"
          x1="1654.55"
          x2="-708.22"
          y1="1907.77"
          y2="119.48"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="p"
          x1="1707.03"
          x2="-655.74"
          y1="1838.43"
          y2="50.14"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="q"
          x1="1759.51"
          x2="-603.26"
          y1="1769.1"
          y2="-19.19"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="r"
          x1="1811.99"
          x2="-550.78"
          y1="1699.76"
          y2="-88.53"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="s"
          x1="1878.5"
          x2="-484.27"
          y1="1611.88"
          y2="-176.41"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="t"
          x1="1951.93"
          x2="-410.83"
          y1="1514.86"
          y2="-273.44"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="u"
          x1="2015.84"
          x2="-346.93"
          y1="1430.42"
          y2="-357.87"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="v"
          x1="2086.46"
          x2="-276.3"
          y1="1337.11"
          y2="-451.18"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="w"
          x1="2163.01"
          x2="-199.76"
          y1="1235.97"
          y2="-552.32"
          xlinkHref="#a"
        ></linearGradient>
        <linearGradient
          id="x"
          x1="2224.87"
          x2="-137.9"
          y1="1154.24"
          y2="-634.05"
          xlinkHref="#a"
        ></linearGradient>
      </defs>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <rect
            width="156.41"
            height="31.28"
            x="1480.64"
            fill="url(#a)"
            rx="10.89"
          ></rect>
          <rect
            width="274.58"
            height="31.28"
            x="1299.9"
            y="118.17"
            fill="url(#b)"
            rx="10.89"
          ></rect>
          <rect
            width="229.39"
            height="31.28"
            x="1303.38"
            y="351.04"
            fill="url(#c)"
            rx="10.89"
          ></rect>
          <rect
            width="250.25"
            height="31.28"
            x="1386.8"
            y="472.69"
            fill="url(#d)"
            rx="10.89"
          ></rect>
          <rect width="795.93" height="31.28" fill="url(#e)" rx="10.89"></rect>
          <rect
            width="288.48"
            height="31.28"
            y="118.17"
            fill="url(#f)"
            rx="10.89"
          ></rect>
          <rect
            width="795.93"
            height="31.28"
            y="236.35"
            fill="url(#g)"
            rx="10.89"
          ></rect>
          <rect
            width="340.62"
            height="31.28"
            x="455.31"
            y="354.52"
            fill="url(#h)"
            rx="10.89"
          ></rect>
          <rect
            width="795.93"
            height="31.28"
            y="472.69"
            fill="url(#i)"
            rx="10.89"
          ></rect>
          <rect
            width="156.41"
            height="31.28"
            x="928.01"
            fill="url(#j)"
            rx="10.89"
          ></rect>
          <rect
            width="156.41"
            height="29.33"
            x="928.01"
            y="118.17"
            fill="url(#k)"
            rx="10.89"
          ></rect>
          <rect
            width="312.81"
            height="29.33"
            x="928.01"
            y="234.39"
            fill="url(#l)"
            rx="10.89"
          ></rect>
          <rect
            width="156.41"
            height="31.28"
            x="928.01"
            y="350.61"
            fill="url(#m)"
            rx="10.89"
          ></rect>
          <rect
            width="156.41"
            height="31.28"
            x="928.01"
            y="468.78"
            fill="url(#n)"
            rx="10.89"
          ></rect>
          <path
            fill="url(#o)"
            d="M20.44 733.35l35.38-90.59h9.76L30.2 733.35z"
          ></path>
          <path
            fill="url(#p)"
            d="M164.53 733.35l35.38-90.59h9.76l-35.38 90.59z"
          ></path>
          <path
            fill="url(#q)"
            d="M308.62 733.35L344 642.76h9.76l-35.38 90.59z"
          ></path>
          <path
            fill="url(#r)"
            d="M452.71 733.35l35.38-90.59h9.76l-35.38 90.59z"
          ></path>
          <path
            fill="url(#s)"
            d="M624.46 731.9v-94.61h57.74v10h-46.36V679h43.65v10h-43.65v32.8h46.36v10z"
          ></path>
          <path
            fill="url(#t)"
            d="M832.12 731.9l-31.45-94.61h12.2L828.46 685l10.44 35.51h.67L850.28 685 866 637.29h11.8L846 731.9z"
          ></path>
          <path
            fill="url(#u)"
            d="M1001.56 731.9v-94.61h57.74v10H1013V679h43.64v10H1013v32.8h46.35v10z"
          ></path>
          <path
            fill="url(#v)"
            d="M1211.93 674.16l-11.38-21h-.41v78.74H1189v-94.61h13.14l34.6 57.71 11.38 21h.41v-78.71h11.11v94.61h-13.14z"
          ></path>
          <path
            fill="url(#w)"
            d="M1425.15 647.32v84.58h-11.38v-84.58h-30.09v-10h71.56v10z"
          ></path>
          <path
            fill="url(#x)"
            d="M1570.87 717l8.41-7c6.64 8.81 14.5 13.56 25.48 13.56 13.55 0 20.87-7 20.87-17.9 0-8.94-5-13.41-17.48-16.26l-7.73-1.76c-17.21-3.93-26.7-11.52-26.7-26 0-16.8 12.74-25.89 31.31-25.89 14.23 0 24.26 5.43 31.31 15.46l-8.54 6.23c-5.15-7.18-12.33-11.66-23.31-11.66-12.07 0-19.52 5.29-19.52 15.46 0 8.94 5.83 13 17.76 15.72l7.72 1.76c18.44 4.2 26.43 12.34 26.43 26.43 0 17.35-12.2 28.47-32.53 28.47-15.18-.09-25.89-6.33-33.48-16.62z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Logo_Colored;
