import React from "react";
import { Instgram, Facebook } from "../assets/icons";

export default function Footer() {
  return (
    <footer
      className="text-white py-10 "
      style={{
        background: "#073A49",
      }}
    >
      <div className="flex items-center flex-col md:flex-row md:justify-between max-w-5xl mx-auto flex-wrap">
        <div className="w-1/2 lg:w-1/4 px-5  text-center lg:text-left">
          <p className="text-lg">&copy;SK Events {new Date().getFullYear()}</p>
          <p className="text-md">
            Website designed by{" "}
            <a
              href="https://elementsoftworks.co.uk"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Element Softworks Ltd"
            >
              Element Softworks Ltd
            </a>
          </p>
        </div>
        <div className="w-1/2   lg:w-1/2 flex justify-center py-4 md:py-0">
          <a
            href="https://www.instagram.com/skeventsdj/"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Instagram"
          >
            <Instgram />
          </a>
          <a
            href="https://facebook.com/SKEventsDJ/"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Facebook"
          >
            <Facebook />
          </a>
        </div>
        <div className="w-full  lg:w-1/4 flex px-5  lg:pt-0">
          <ul className="w-full">
            <li className="text-center  pt-2 lg:text-right">
              <a
                href="tel:+447487757345"
                rel="noreferrer noopener"
                className="text-lg"
                aria-label="Contact Number"
              >
                07487 757345
              </a>
            </li>
            <li className="text-center text-md pt-2  lg:text-right">
              <a
                href="mailto:skeventsdj@gmail.com"
                rel="noreferrer noopener"
                className="text-lg"
                aria-label="Email"
              >
                skeventsdj@gmail.com
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
