import Axios from "axios";

export const makePostRequest = async (url, data, params = {}, config = {}) => {
  try {
    params = !!params ? params : {};

    return await Axios.post(url, data, {
      params,
      headers: { origin: "http://localhost:8000" },
      timeout: 15000,
    });
  } catch (error) {
    throw error;
  }
};
