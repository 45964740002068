import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const About = () => {
  const data = useStaticQuery(graphql`
    query About {
      allMarkdownRemark(filter: { frontmatter: { path: { eq: "/" } } }) {
        edges {
          node {
            frontmatter {
              about_title
              about_description
              about_image {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const {
    about_title,
    about_description,
  } = data.allMarkdownRemark.edges[0].node.frontmatter;
  const image =
    data.allMarkdownRemark.edges[0].node.frontmatter.about_image.childImageSharp
      .fluid.src;
  return (
    <div
      className="max-w-5xl flex flex-col lg:flex-row items-between mx-auto pt-24 pb-8 px-5"
      id="about"
    >
      <div className="w-full lg:w-1/3 text-5xl md:text-6xl flex   font-bold uppercase">
        {about_title}
      </div>
      <div className="w-full lg:w-2/3 flex flex-wrap-reverse items-center lg:pt-16">
        <div className="w-full lg:w-3/4 text-center lg:text-left px-10 pl-5 lg:pl-0 font-medium text-md pt-4 lg:pt-0 text-gray-900 robotoFont">
          {about_description}
        </div>
        <div className="w-full flex justify-center lg:w-1/4 pt-4 md:pt-0">
          <img className="w-1/3 md:w-1/4 lg:w-full" src={image} alt="Sam"></img>
        </div>
      </div>
    </div>
  );
};

export default About;
