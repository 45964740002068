import React, { useState } from "react";
import FormInput from "../../components/FormInput";
import { useStaticQuery, graphql } from "gatsby";
import { CONTACT } from "../../helpers/api";
import { makePostRequest } from "../../helpers/requests";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
const Contact = () => {
  const [state, setState] = useState({});
  const data = useStaticQuery(graphql`
    query Contact {
      merlinWebsite {
        websiteId
      }
      allMarkdownRemark(filter: { frontmatter: { path: { eq: "/" } } }) {
        edges {
          node {
            frontmatter {
              input_fields {
                placeHolder
                type
                required
              }
              package_deal
              contact_title
            }
          }
        }
      }
    }
  `);
  const {
    input_fields,
    package_deal,
    contact_title,
  } = data.allMarkdownRemark.edges[0].node.frontmatter;
  const { websiteId } = data.merlinWebsite;
  const name = state.name;
  const message = state.message;
  const emailAddress = state.emailAddress;
  const phone = state.phoneNumber;
  const department = state.department;
  const handleChange = (e) => {
    const nameInPascal = _.camelCase(e.target.name);
    state[nameInPascal] = e.target.value;
    console.log("e", e, "state", state);
    setState({ ...state });
  };
  const submitForm = async () => {
    console.log("ebsite", websiteId);
    try {
      const { data: email } = await makePostRequest(
        CONTACT,
        {
          email: emailAddress,
          name,
          message,
          department,
          phone,
        },
        { websiteId }
      );
    } catch (error) {}
  };
  return (
    <div className="max-w-5xl mx-auto py-10 md:py-10 px-5 " id="contact">
      <div className="w-full">
        <div className="text-5xl md:text-6xl font-bold uppercase">
          {contact_title}
        </div>
        <Formik
          validationSchema={Yup.object().shape({
            name: Yup.string().required(`${name} is required`),
            email: Yup.string()
              .email(`Must be an email address`)
              .required(`${emailAddress} is required`),
            department: Yup.string(),
            phone: Yup.string(),
            message: Yup.string().required(`${message} is required`),
          })}
          initialValues={{
            name: undefined,
            email: undefined,
            phone: undefined,
            department: undefined,
            message: undefined,
          }}
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
        >
          <form className="max-w-4xl mx-auto pt-12 flex flex-wrap lg:pl-16 justify-between">
            {input_fields.map((inputData, index) => {
              return (
                <div className="form-input" key={index}>
                  <FormInput
                    data={inputData}
                    handleChange={handleChange.bind(this)}
                  />
                </div>
              );
            })}
            <div className="inline-block relative mb-8 package-select">
              <select
                name="department"
                className="block appearance-none w-full border border-gray-700 px-4 py-3 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-gray-700  placeholder-gray-700 font-medium"
                style={{ WebkitAppearance: "none" }}
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                {package_deal.map((deal, index) => {
                  return <option key={index}>{deal}</option>;
                })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 ">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            <textarea
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder="message"
              name="message"
              className="w-full focus:outline-none focus:shadow-outline border border-gray-700 rounded-md py-2 px-4 block appearance-none leading-normal text-black placeholder-black mb-8 h-32"
            ></textarea>
            <div className="w-full flex justify-end">
              <button
                className="text-white py-3 px-10 rounded-md robotoFont"
                onClick={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
                style={{
                  background:
                    "radial-gradient(closest-side at 28% 402%, rgb(226, 20, 143) 0%, rgb(183, 64, 170) 100%, rgb(64, 184, 244) 350%) 0% 0% no-repeat padding-box padding-box transparent",
                  bottom: "5%",
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </Formik>
      </div>
    </div>
  );
};

export default Contact;
